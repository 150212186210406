@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');
:root{
    overflow-x: hidden;
    overflow-y: scroll;
}
.IdentifyScreen {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 100%  ;
  top: 0px;
}

.IdentifyScreen-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width:123px;
    top: 8.37%;
    height:18px;

}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    cursor: pointer;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}

.Identity-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}
.Identity-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Identity-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Identity-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Identity-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #C4C4C4;
}

.Identity-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Identity-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #C4C4C4;
}
.Identity-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Identity-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;
}
.Identity-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Identity-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Identity-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    
    
    /* Completed */
    
    
}

.How-Identify{
    position: absolute;
   
    height: 24px;
    left: 50%;
    width: 280px;
    transform: translateX(-50%);
    top: 28.9458%;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    max-lines: 1;
    letter-spacing: -0.02em;
    
    /* Reframe - Safe Blue */
    
    color: #213088;
}


.Thanks-Text{
    position: absolute;
width: 280px;
height: 56px;
left: calc(50% - 280px/2);
top: 18.9285%;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 17px;
letter-spacing: -0.02em;

color: #2E2D3D;
}




.Identity-Main-Div{
    position: absolute;
    width: 243px;
    height: 245px;
    left: calc(50% - 245px/2);
    top: 40.59605%;
}



.Female-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
cursor: pointer;
height: 39px;


border: 1px solid #213088;
border-radius: 8px;
}
.Female-Text{
    width: 231px;
    height: 39px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 39px;
    left:6px;
   
    position: absolute;
    /* identical to box height */
    
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
    
    /* Inside auto layout */
    
  
    
}
.Male-Div{
    box-sizing: border-box;
    cursor: pointer;

position: absolute;
width: 243px;
height: 39px;
top: 51px;


border: 1px solid #213088;
border-radius: 8px;
}
.Male-Text{
    position: absolute;
    width: 180.84px;
    height: 39px;
    left: 32.29px;
    margin: 0;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #213088;
}
.Non-Binary-Div{
    box-sizing: border-box;
    cursor: pointer;

position: absolute;
width: 243px;
height: 39px;
top:102px;


border: 1px solid #213088;
border-radius: 8px;
}
.Non-Binary-Text{
    position: absolute;
    width: 217px;
    height: 39px;
    left: 15px;
   
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
}
.Self-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
height: 39px;
top:154px;

cursor: pointer;

border: 1px solid #213088;
border-radius: 8px;
}
.Self-Text{
    position: absolute;
    width: 217px;
    height: 39px;
    left: 14px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
}
.Prefer-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
height: 39px;
top:102px;

cursor: pointer;

border: 1px solid #213088;
border-radius: 8px;
}
.Prefer-Text{
    position: absolute;
    width: 217px;
    height: 39px;
    left: 15px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
}














.Ready-Section div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 38.03px;
    left: 45px;
    top: 680px;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}