@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.HearAbout {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  

  height: 100%  ;
  top: 0px;


}

.HearAbout-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width: 123px;
    height: 18px;
    top: 8.37%;
   
  background: url(Horizontal_Logo_Smaller.png);
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}
.Hear-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}

.Hear-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Hear-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Hear-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Hear-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #C4C4C4;
}

.Hear-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Hear-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #C4C4C4;
}
.Hear-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Hear-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;
}
.Hear-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Hear-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Hear-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
  
    
    
    /* Completed */
    
    

.How-Hear{
    position: absolute;
    width: 232px;
    height: 16px;
    left: calc(50% - 232px/2);
    top: 29.55665%;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    
    /* Reframe - Safe Blue */
    
    color: #213088;
}




.Hear-Main-Div{
    position: absolute;
    width: 243px;
    height: 317px;
    left: calc(50% - 243px/2);
    top: 33.5911%;
}



.Ads-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
cursor: pointer;
height: 30px;


border: 1px solid #213088;
border-radius: 8px;
}
.Ads-Text{
    width: 68px;
    height: 17px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    position: absolute;
    left: 87.19px;
    top: 5.96px;
    line-height: 17px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
}
.News-Div{
    box-sizing: border-box;
    cursor: pointer;

position: absolute;
width: 243px;
height: 30px;
top: 40.85px;


border: 1px solid #213088;
border-radius: 8px;
}
.News-Text{
    width: 119px;
    height: 17px;
    left: 61.5px;
    top: 5.65px;
    position: absolute;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
.Web-Div{
    box-sizing: border-box;
    cursor: pointer;

position: absolute;
width: 243px;
height: 30px;
top:82px;


border: 1px solid #213088;
border-radius: 8px;
}
.Web-Text{
    width: 76px;
    height: 17px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    position: absolute;
    left: 83.14px;
    top:6.96px;
    
    
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
.Insta-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
height: 30px;
top:123px;

cursor: pointer;

border: 1px solid #213088;
border-radius: 8px;
}
.Insta-Text{
    width: 68px;
height: 17px;
margin: auto;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
/* identical to box height */
position: absolute;
left: 89.24px;
top: 6.96px;

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #213088;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.TikTok-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
height: 30px;
top:164px;

cursor: pointer;

border: 1px solid #213088;
border-radius: 8px;
}
.TikTok-Text{
    width: 68px;
height: 17px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
margin: auto;

line-height: 17px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #213088;
position: absolute;
left: 101px;
top:4px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.Facebook-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
height: 30px;
top:205px;
cursor: pointer;


border: 1px solid #213088;
border-radius: 8px;
}
.Facebook-Text{
    width: 68px;
height: 17px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
margin: auto;
position: absolute;
left: 89px;
top: 4px;

line-height: 17px;
/* identical to box height */

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #213088;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.App-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
height: 30px;
top:246px;
cursor: pointer;


border: 1px solid #213088;
border-radius: 8px;
}
.App-Text{
    width: 100px;
    height: 17px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    left:86px;
    top:6.5px;
    position: absolute;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
.Friends-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
height: 30px;
top:287px;
cursor: pointer;


border: 1px solid #213088;
border-radius: 8px;
}
.Friends-Text{
    width: 90px;
    height: 17px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    left: 76px;
    top:4px;
    line-height: 17px;
    /* identical to box height */
    
    display: block;
    position: absolute;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}




.Other-Div{
    position: absolute;
width: 243px;
height: 58px;
left: 0px;
top:315px;
}

.Other-Text{
    width: 42px;
height: 17px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #213088;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}



.Other-Input{

    box-sizing: border-box;

position: absolute;
width: 243px;
height: 30px;
top: 24px;
left: 0px;

background: #FFFFFF;
/* Brand Colors/Safe Blue */

border: 1px solid #213088;
border-radius: 8px;
}
.Other-Input:focus{

    box-sizing: border-box;

position: absolute;
width: 243px;
height: 30px;
top: 24px;
left: 0px;


background: #FFFFFF;
/* Brand Colors/Safe Blue */

border: 1px solid #213088;
border-radius: 8px;
}







.Ready-Section div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 38.03px;
    left: 45px;
    top: 680px;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}