@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

:root{
    width: 100%;
    overflow: hidden;
    height: 100%;
}
.MapOnboardingScreen {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  overflow: hidden;

  height: 100%  ;
  top: 0px;


}

.Map-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width: 123px;
    top: 8.37%;
    height:18px;
  background: url(Horizontal_Logo_Smaller.png);
}
.Map-Section {
    position: absolute;
    width: 317px;
    height: 188px;

    left: calc(50% - 317px/2);
    top: 37.5615%;


background: url(World_map_blue_dots.png);
}


.Around-World{
    position: absolute;
    width: 283px;
    height: 86px;
    left: calc(50% - 283px/2);
    top: 14.4088%;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #213088;
}

.Map-Ready-Button a{
    width: 100px;
height: 22px;
left: 105px;


font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
display: block;
align-items: center;

text-align: center;
letter-spacing: -0.02em;

/* Brand Colors/White */

color: #ffffff;


/* Inside auto layout */

}

.Map-Ready-Button[type ='button']{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 39px;
    left: calc(50% - 285px/2);
    top: 83.74%;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}
