@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.TypeDrink {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  

  height: 100%  ;
  top: 0px;


}

.TypeDrink-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width: 123px;
    top: 8.37%;
   height: 18px;
  background: url(Horizontal_Logo_Smaller.png);
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}

.Type-Drink-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}
.Type-Drink-Progress-Indicator-Gradient{
    position: absolute;
left: 26px;
width:136px;
height: 8px;

top: 11px;


background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
border-radius: 2.61658px;
}

.Type-Drink-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Type-Drink-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Type-Drink-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Type-Drink-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #1160A5;
}

.Type-Drink-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Type-Drink-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #1D3F91;
}
.Type-Drink-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Type-Drink-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;
}
.Type-Drink-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Type-Drink-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Type-Drink-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    
    
    /* Completed */
    
    
}


.Type-Drink-Text{
    position: absolute;
left: calc(50% - 254px/2);

top: 29.06%;


font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
text-align: left;
letter-spacing: -0.02em;

/* Reframe - Safe Blue */

color: #213088;
    
}

.Type-Main-Div{
    position: absolute;
    width: 243px;
    height: 300px;
    left: calc(50% - 243px/2);
    top: 38.3%;
}



.Beer-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
cursor: pointer;
height: 39px;
top:3px;


border: 1px solid #213088;
border-radius: 8px;
}
.Beer-Text{
    position: absolute;
width: 233.58px;
height: 39px;
left: 1.9px;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 39px;
display: block;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #213088;

  
    
}
.Cocktails-Div{
    box-sizing: border-box;
    cursor: pointer;

position: absolute;
width: 243px;
height: 39px;
top: 54px;


border: 1px solid #213088;
border-radius: 8px;
}
.Cocktails-Text{
    position: absolute;
    width: 180.84px;
    height: 39px;
    left: 24.3px;
 
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
}
.Wine-Div{
    box-sizing: border-box;
    cursor: pointer;

position: absolute;
width: 243px;
height: 39px;
top:105px;


border: 1px solid #213088;
border-radius: 8px;
}
.Wine-Text{
    position: absolute;
    width: 216.63px;
    height: 39px;
    left: 12.86px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
    
}
.Spirits-Div{
    box-sizing: border-box;

position: absolute;
width: 243px;
height: 39px;
top:156px;

cursor: pointer;

border: 1px solid #213088;
border-radius: 8px;
}
.Spirits-Text{
    position: absolute;
    width: 216.63px;
    height: 39px;
    left: 12.6px;

    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
}








