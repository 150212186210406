@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.WhyChange {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  

  height: 100%  ;
  top: 0px;


}

.WhyChange-header {
    position: absolute;
    left: calc(50% - 123px/2);
   width:123px;
    top: 8.37%;
    height:18px;
  background: url(Horizontal_Logo_Smaller.png);
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}
.Why-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}
.Why-Progress-Indicator-Gradient{
    position: absolute;
left: 26px;
width:80px;
height: 8px;

top: 11px;


background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
border-radius: 2.61658px;
}
.Why-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Why-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Why-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Why-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #1160A5;
}

.Why-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Why-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #C4C4C4;
}
.Why-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Why-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;
}
.Why-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Why-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Why-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    
    
    /* Completed */
    
    
}






.Why-Main-Div{
    position: absolute;
width: 307px;
height: 417px;
left: calc(50% - 307px/2);
top: 29.5566%;


}
.Why-Change-Text{
    position: absolute;
    width: 307px;
    height: 44px;
    left: 0px;
   
    text-align: left;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    
    /* Reframe - Safe Blue */
    
    color: #213088; 
}
.Lets-Start{
    position: absolute;
width: 213px;
height: 20px;

top: 61px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}
.Choose-Later{
    position: absolute;
width: 143px;
height: 15px;

top: 81px;

font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 11px;
line-height: 13px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Options-Div{
    position: absolute;
width: 260px;
height: 294px;
left: 17px;
top: 103px;
}


.Health-Div{
    box-sizing: border-box;

position: absolute;
width: 260px;
cursor: pointer;
height: 39px;



border: 1px solid #213088;
border-radius: 8px;
}
.Health-Text{
    position: absolute;
    width: 216.41px;
    height: 39px;
    left: 23.63px;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 39px;
display: block;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #213088;

  
    
}
.Sleep-Div{
    box-sizing: border-box;
    cursor: pointer;

position: absolute;
width: 260px;
height: 39px;
top: 51px;


border: 1px solid #213088;
border-radius: 8px;
}
.Sleep-Text{
    position: absolute;
width: 103.24px;
height: 39px;
left: 78.68px;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 39px;
display: block;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #213088;
    
}
.Effects-Div{
    box-sizing: border-box;
    cursor: pointer;

position: absolute;
width: 260px;
height: 39px;
top:102px;


border: 1px solid #213088;
border-radius: 8px;
}
.Effects-Text{
    position: absolute;
    width: 253.12px;
    height: 39px;
    left: 3.06px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
    
}
.Relationships-Div{
    box-sizing: border-box;

position: absolute;
width: 260px;
height: 39px;
top:153px;

cursor: pointer;

border: 1px solid #213088;
border-radius: 8px;
}
.Relationships-Text{
    position: absolute;
width: 209.53px;
height: 39px;
left: 26.68px;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 39px;
display: block;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #213088;

    
}
.Feel-Better-Div{
    box-sizing: border-box;

position: absolute;
width: 260px;
height: 39px;
top:204px;

cursor: pointer;

border: 1px solid #213088;
border-radius: 8px;
}
.Feel-Better-Text{
    position: absolute;
    width: 198.06px;
    height: 39px;
    left:32.12px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
}
.Money-Div{
    box-sizing: border-box;

position: absolute;
width: 260px;
height: 39px;
top:255px;

cursor: pointer;

border: 1px solid #213088;
border-radius: 8px;
}
.Money-Text{
    position: absolute;
    width: 79.76px;
    height: 39px;
    left: 89.73px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 39px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #213088;
    
}











.Ready-Section div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 38.03px;
    left: 45px;
    top: 680px;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}