@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.App {
  text-align: center;
  position: absolute;
  width: 100%;
  background:white;
 
  

  height: 100%;
  top: 0px;


}

.App-header {
  position: absolute;
left: calc(50% - 255px/2);
width:255px;
top: 5.15%;
height:39px;
z-index: 1;
background: url(Horizontal_Logo.png);
}


.Brain_Anim_Div{
 width: 100%;
 height:100%;
 z-index: -1;
}

.Reduction-Platform{
  position: absolute;
width: 330px;
height: 87px;
left: calc(50% - 330px/2);
top: 19.82%;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #213088;
}
.Next-Section{
    cursor: pointer;  
  }

.Next-Text{
  position: absolute;
  width: 354px;
  height: 86px;
  justify-content: center;
align-items: center;
  
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}


.Next-Button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: 0;
  
  padding: 15px 32px 0x 0x;
  gap: 10px;
  display: flex;
align-items: center;
text-align: center;
  
  position: absolute;
  width: 285px;
  cursor: pointer;
  height: 38.03px;

  left: calc(50% - 285px/2);
  right: calc(50% - 285px/2);
  top: 83.74%;

  
  /* Brand Colors/Safe Blue */
  
  background: #213088;
  border-radius: 50px;
}

.Transition-Section{
    position: absolute;
    width: 584px;
    height: 584px;
    left: calc(50% - 584px/2);
    top: calc(83.74% - 584px/2);
    opacity: 0;
    z-index: 0;
    
    
    /* Gradients/Screens Background */
    
    background: linear-gradient(180deg, #FFFFFF 0%, #d6EBFF 100%);
    border-radius: 100%;

}
