@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.GotYourBack {
  text-align: center;
  background: #213088;
  position: absolute;
  width: 100%;
  

  height: 100%  ;
  top: 0px;


}

.GotYourBack-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width: 123px;
    top: 8.37%;
    height: 18px;
  background: url(Horizontal_Logo_White.png);
}
.Circles-Section {
    position: absolute;
left: calc(50% - 175px/2);

top: 12.44%;


background: url(ConcentricCircles.svg);
}

.Got-Your-Back-Text{
    position: absolute;
    left: calc(50% - 238px/2);
  
    top: 44.29%;
 
    
    font-family: 'Inter';
    font-style: normal;
    text-align: left;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
    
}


.Every-Step-Text{
    position: absolute;
    left: calc(50% - 256px/2);
    top: 51.11%;
   
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    
    color: #FFFFFF;
}

.Got-You-Button a{
    position: absolute;
    width: 285px;
    height: 19px;
    justify-content: center;
    align-items: center;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #213088;
}

.Got-You-Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 38.03px;
    left: calc(50% - 285px/2);
    top: 83.7438%;
    
    /* Brand Colors/Safe Blue */
    
    background: white;
    border-radius: 50px;
}

