@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');
:root{
    overflow-x: hidden;
    overflow-y: scroll;
}
.NameScreen {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  
  

  height: 100%  ;
  top: 0px;


}

.Name-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width: 123px;
    height: 18px;
    top: 8.37%;
   
}

.Popup-Blur-Div{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: 1;
    background: #DEEFFF;

}
.Popup-Div{
    width: 375px;
    left: calc(50% - 375px/2);
    top: calc(50% - 644px/2);
    height: 644px;
    
    position: absolute;
    z-index: 4;
    vertical-align: middle;
    margin: 0px;
    
    
}

.Ios-Only-Popup{
    position: absolute;
    width: 351px;
    height: 40px;
    top: 70px;
    visibility: hidden;
    left: 12px;
    border-radius: 12px;
    background: linear-gradient(90deg, #213088 0%, #018ABE 100%);
}
.Ios-Only-Text{
    font-weight: 700;
    position: relative;
    font-size: 20px;
    line-height: 36px;
    z-index: 4;
    font-family: 'Inter';
    color: #fff;
    letter-spacing: -0.02em;
    font-style: normal;
    width: 260px;
    max-lines: 1;
    height: 37px;
    text-align: left;
    left: 0px;
    right: 54px;
    top:0px;
}
.Referal-Discount-Div{
    position: absolute;
    width: 351px;
    height: 126px;
    top: 124px;
    
    left: 12px;
    border-radius: 12px;
    background: linear-gradient(90deg, #213088 0%, #018ABE 100%);
}
.Cancel-Icon-Div{
    position: absolute;
    background: url(CancelIcon.svg);
    height: 10px;
    width: 10px;
    left: 331px;
    cursor: pointer;
    top: 10px;
}
.You-Got-Text{
font-weight: 600;
position: absolute;
font-size: 20px;
line-height: 24px;
z-index: 4;
font-family: 'Inter';
color: #fff;
letter-spacing: -0.02em;
font-style: normal;
width: 255px;
height: 24px;
left: 42px;
top:32px;
text-align: left;

}
.Referal-Discount-Text{
    font-weight: 700;
position: relative;
font-size: 30px;
line-height: 36px;
z-index: 4;
font-family: 'Inter';
color: #fff;
letter-spacing: -0.02em;
font-style: normal;
width: 260px;
max-lines: 1;
height: 37px;
text-align: left;
left: 0px;
right: 54px;
top:60px;
}
.Confetti-Div{
    position: absolute;
    top:32px;
    left: 241px;
    z-index: 4;
    width:92px;
    opacity: 0.5;
    height: 92px;

    background: url(ConfettiVector.svg);
}

.Special-Offer-Div{
    position: absolute;
    left: 12px;
    height: 327px;
    width: 351px;
    top: 265px;
    border-radius: 10px;
    background: #fff;
}
.Fireworks-Div{
    position: absolute;
    width: 345px;
    height: 223px;
    left: 3px;
    top: 91px;
    background: url(FireworksIcon.svg);
}
.Special-Offer-Text{
position: absolute;
left:34px;
top: 27px;
width:300px;
height: 64px;
font-family: 'Inter';
font-weight: 400;
font-size: 25px;
line-height: 31px;
color: #213088;


}
.Popup-Free-Trial-Text{
    text-align: center;
    position: absolute;
    width: 279px;
    height: 32px;
    line-height: 32px;
    font-weight: 700;
    font-size: 25px;
    color: #213088;
    left: 34px;
    top: 67px;
}
.Claim-Now-Button{
    position: absolute;
    width: 320px;
    height: 52px;
    left: 16px;
    top:249px;
    background: #213088;
    border-radius: 100px;
    cursor: pointer;

}
.Claim-Now-Text{
    height: 52px;
    line-height: 52px;
    font: 'Inter';
    font-size: 20px;
    font-weight: 700;
    color: white;
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}

.Name-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}
.Name-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Name-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Name-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Name-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #C4C4C4;
}

.Name-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Name-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #C4C4C4;
}
.Name-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Name-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;
}
.Name-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Name-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Name-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    
    
    /* Completed */
    
    
}


.Welcome{
    position: absolute;
width: 278px;
height: 30px;
left: 14px;
top: 0px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Name-Main-Div{
    position: absolute;
width: 335px;
height: 196px;
left: calc(50% - 278px/2);
top: 29.55%;


}
.Call-You{
    position: absolute;
    width: 321px;
    height: 18px;
    left: 14px;
    top: 84px;
    text-align: left;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    
    /* Brand Colors/Safe Blue */
    
    color: #213088;  
}




.Name-Form{
    position: relative;
width: 295px;
height: 40px;
top: 156px;
left:0px;

border-color: #FFFFFF;
}


.First-Name{
    box-sizing: border-box;

    position: absolute;
    width: 295px;
    height: 40px;
    left:15.77px;
    top:162px;
    

    background: #FFFFFF;
    border-radius: 5px;
    border-color: transparent;
    font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 22px;
display: flex;
letter-spacing: -0.02em;

/* Grey */

color: #8A8A8A;
}
.First-Name:focus{
    outline:none;
    left:15.77px;
    top:162px;
    position: relative;
}


.First-Name::placeholder {
    position: relative;
font-family: 'Inter';
font-style: normal;
height:22px;
font-weight: 300;
font-size: 18px;
line-height: 22px;
display: block;
letter-spacing: -0.02em;
/* Grey */
color: #8A8A8A;
}





.Name-Ready-Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    cursor: pointer;
    
    position: absolute;
    width: 285px;
    height: 39px;
    left: calc(50% - 285px/2);
    top: 83.743%;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}

.Name-Ready-Button a{
    width: 100px;
    height: 22px;
    left: 105px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: block;
    align-items: center;
    
    text-align: center;
    letter-spacing: -0.02em;
    
    /* Brand Colors/White */
    
    color: #ffffff;
    
}