@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');
:root{
    overflow-x: hidden;
    overflow-y: scroll;
}
.SignUp {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  

  height: 812px  ;
  top: 0px;


}

.SignUp-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width:123px;
    top: 8.37vh;
    height:18px;
    
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    cursor: pointer;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}

.Sign-Up-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}
.Sign-Up-Progress-Indicator-Gradient{
    position: absolute;
left: 26px;
width:211px;
height: 8px;
top: 11px;
background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
border-radius: 2.61658px;
}
.Sign-Up-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;
background: #F4F4F4;
border-radius: 2.61658px;
}

.Sign-Up-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Sign-Up-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Sign-Up-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #1160A5;
}

.Sign-Up-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Sign-Up-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #1D3F91;
}
.Sign-Up-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Sign-Up-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #213189;
}
.Sign-Up-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Sign-Up-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Sign-Up-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Completed */
}

.Error-Text{
    position: absolute;
    left: calc(50% - 230px/2);
    top: 440px;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 14px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: red;
}

.Sign-Up-Main-Div{
    position: absolute;
    left: calc(50% - 285px/2);
    top: 241px;
   
}

.Email-Text{
    position: absolute;
left: 1.37px;
width: 100px;
top:5px;
visibility: hidden;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Placeholder-Email-Text{
    position: absolute;
    left: 5.47px;
    top:24px;
    width:285px;
cursor: pointer;

font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
letter-spacing: -0.02em;
z-index: 3;

/* Grey */

color: #8A8A8A;
}





.Email-Input{
    position: absolute;
   visibility: hidden;
    
    
    font-family: 'Inter';
    border: 1px solid #213088;
    border-radius: 12px;
    font-style: normal;
    left: 5.47px;
    width: 285px;
    height: 39px;
    font-weight: 300;
    font-size: 14px;
    top:24px;
    z-index: 1;
    

    left: 5.47px;
   
    line-height: 17px;
    display: block;
    padding: 0px;
    padding-left: 5.47px;
    margin: 0;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* Brand Colors/Safe Blue */
    
    color: #213088;
}




.Email-Input::placeholder {
    position: absolute;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    
    width: fit-content;
    height: 39px;
   
    left: 5.47px;
    top:0px;
   
    display: block;
    line-height: 39px;
    padding: 0;
    margin: 0;
    text-align: left;
    align-items: center;
    letter-spacing: -0.02em;
   

    /* Brand Colors/Safe Blue */
    
    color: #213088;


}


.Password-Text{
    position: absolute;
left: 1.37px;
width: 285px;
top: 70px;
visibility: hidden;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Placeholder-Password-Text{
    position: absolute;
    top:93px;
    left: 5.47px;
    z-index: 3;
    width: 285px;
    cursor: pointer;

font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

/* Grey */

color: #8A8A8A;
}




.Password-Input{
    position: absolute;
    visibility: hidden;

    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    width: 285px;
    height: 39px;
    font-size: 14px;
    top:88px;
    left: 5.47px;
    text-indent: 5.47px;
    line-height: 17px;
    display: block;
    padding: 0;
    margin: 0;
    text-align: left;
   
    align-items: center;
    letter-spacing: -0.02em;
    border: 1px solid #213088;
    z-index: 1;
    border-radius: 12px;
    
    /* Brand Colors/Safe Blue */
    
    color: #213088;
}



 .Password-Input::placeholder {
    position: absolute;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    
    width: fit-content;
    height: 39px;
   
    left: 0px;
    top:0px;
   
    display: block;
    line-height: 39px;
    padding: 0;
    margin: 0;
    text-align: left;
    align-items: center;
    letter-spacing: -0.02em;
   

    /* Brand Colors/Safe Blue */
    
    color: #213088;

}





  .Agreement-Circle{
    box-sizing: border-box;

    position: absolute;
    width: 12px;
    height: 12px;
    top:241px;
    
    border: 1px solid #737272;
    border-radius: 40px;
}
.Filled-Agreement-Circle{
    box-sizing: border-box;

position: absolute;
width: 6px;
height: 6px;
top:244px;
left: 3px;
opacity: 0;

background: #213088;
border-radius: 40px;
}
.Agreement-Circle:focus{
    box-sizing: border-box;

position: absolute;
width: 12px;
height: 12px;
top:241px;

border: 1px solid #213088;
border-radius: 40px;
}



.Terms-Div{
    position: absolute;
     left: calc(50% - 285px/2);;
    right: calc(50% - 285px/2);
    top: 160px;
    
    
}

.Terms-Text{
    position: absolute;

left: 19px;
top: 241px;
width: 285px;


font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 13px;
display: inline;
align-items: center;
text-align: left;
letter-spacing: -0.02em;

color: #737272;
}

.Sign-Up-Text{
    position: relative;
    left: 0px;  
    top:0px;
    width:285px;
    height: 62px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding: 0;
  margin: 0;
  text-align: left;
  letter-spacing: -0.02em;
  
  /* Reframe - Safe Blue */
  
  color: #213088;
  }
  
  
  .Required-Field{
      position: absolute;
      
      margin: 0px;
      top: 62px;
      
      left: 0px;
     
      
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.02em;
      
      color: #585858;
      
  }








.Sign-Up-Ready-Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    cursor: pointer;
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 39px;
    left: calc(50% - 285px/2);
    top: 660px;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}

.Sign-Up-Ready-Button a{
    width: 100px;
    height: 22px;
    left: 105px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: block;
    align-items: center;
    
    text-align: center;
    letter-spacing: -0.02em;
    
    /* Brand Colors/White */
    
    color: #ffffff;
    
}
.Disclaimer-Text{
    position: absolute;
    width: 279px;
left: calc(50% - 279px/2);
top: 710px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 13px;
display: flex;
align-items: center;
text-align: left;
letter-spacing: -0.02em;
color: #585858;
}