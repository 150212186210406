@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&family=Poppins&display=swap');
:root{
    overflow-x: hidden;
    overflow-y: scroll;
}
.TypicalWeek {
    overflow-y: scroll;
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  

  height: 812px ;
  top: 0px;


}
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

.TypicalWeek-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width: 123px;
    top: 8.37%;
    height: 18px;
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}

.Typical-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}
.Typical-Progress-Indicator-Gradient{
    position: absolute;
left: 26px;
width:136px;
height: 8px;

top: 11px;


background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
border-radius: 2.61658px;
}

.Typical-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Typical-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Typical-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Typical-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #1160A5;
}

.Typical-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Typical-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #1D3F91;
}
.Typical-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Typical-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;
}
.Typical-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Typical-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}

.Typical-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    
    
    /* Completed */
    
    
}








.Typical-Week-Question{
    position: absolute;
    left: calc(50% - 272px/2);
    right:calc(50% - 272px/2);
   
    top: 173px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    letter-spacing: -0.02em;
    
    /* Reframe - Safe Blue */
    
    color: #213088;
}
.Drinks-Text{
    position: absolute;
left: calc(50% - 272px/2);

top: 230px;
margin-top: 10px;


font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;

}
.Per-Week-Drinks-Text{
    position: absolute;
left:calc(50% - 272px/2);

top:245px;
text-align: left;
margin-top: 15px;
height: 13px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 13px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #8C8C8C;
}

.Drinks-Per-Week-Div{
    position: absolute;
    top:246px;
    width:150px;
    left: calc(60% - 75px);
}
.Minus-Drinks-Per-Week{
   
        background: url(Minus-In-Circle.svg);
        position: absolute;
        left: 0px;
      
        vertical-align: middle;
   
        top: 3px;
        width: 18px;
        height: 18px;
        /* Brand Colors/Blue */        
        cursor: pointer;

        /* Completed */
    
}
.Plus-Drinks-Per-Week{

position: absolute;
left: 92px;
vertical-align: middle;
   
    top: 3px;
cursor: pointer;
width: 18px;
height: 18px;

/* Brand Colors/Blue */
background: url(Plus-In-Circle.svg);
fill: #018ABE;

}


.Drinks-Input-Div{
    position: absolute;
    left: 45px;

    display: block;
    top: 0px;
    vertical-align: middle;
    height:24px;
}
.Typical-Drinks-Per-Week-Counter-Text{
    position: relative;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
margin: 0px;
bottom: 5px;
left:0%;
transform: translateX(-0%);

vertical-align: middle;
width:72px;
text-align: left;
font-size: 24px;
text-align: left;
line-height: 24px;
display: inline;
border: none;
outline: none;
color: #213088;

}
.Typical-Drinks-Per-Week-Counter-Text::placeholder{
    position: absolute;

font-family: 'Poppins';
font-style: normal;
text-align: center;
display: block;
text-align: left;

left: 0px;
font-weight: 500;
font-size: 24px;
margin: 0;
line-height: 24px;
border: none;
outline: none;
color: #213088;

}

.Typical-Drinks-Per-Week-Counter-Text:focus{
    position: absolute;

    display: block;

    margin: 0px;
    bottom:-8px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 24px;
border: none;
outline: none;
color: #213088;

}

.Price-Text{
    position: absolute;
    left: calc(50% - 272px/2);
    
    top: 298px;
    margin-top: 10px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #585858;

}
.Per-Week-Price-Text{
    position: absolute;
    left: calc(50% - 272px/2);
    top: 312px;
    margin-top: 15px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #8C8C8C;
}
.Price-Per-Week-Div{
    width:150px;
    left: calc(60% - 75px);
    top:305px;
    height: 24px;;
    
 
    position: absolute;
}
.Minus-Price-Per-Week{
    background: url(Colored-Minus-In-Circle.svg);
    position: absolute;
    left: 0px;
    top: 3px;;
  
    vertical-align: middle;
    width: 18px;
    height: 18px;
    
    /* Brand Colors/Blue */
    
    fill: #018ABE;
    cursor: pointer;

    
    /* Completed */
    
   
}
.Plus-Price-Per-Week{
    position: absolute;
    left: 92px;
   
    vertical-align: middle;
    width: 18px;
    height: 18px;
    top:3px;
    cursor: pointer;
    
    /* Brand Colors/Blue */
    
    fill: #018ABE;
/* Brand Colors/Blue */
background: url(Plus-In-Circle.svg);
}

.Price-Input-Div{
    position: relative;
    left: 33px;
    display: block;
    height: 24px;
    

    vertical-align: middle;
}
.Dollar-Sign{
    position: absolute;
    left: 0px;
    top:0px;
   vertical-align: middle;
   height: 24px;
    
    
    display: block;
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    
    /* Brand Colors/Safe Blue */
    border: none;
outline: none;
    color: #213088;
}
.Price-Per-Week-Counter-Text{
    position: relative;
    
    
    width: 72px;
   margin: 0px;
   
   padding: 0px;
   left: 13px;
  bottom: 5px;
 
    
  
    z-index: -1;
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    display: block;
    
    /* Brand Colors/Safe Blue */
    border: none;
outline: none;
    color: #213088;

}

.Price-Per-Week-Counter-Text::placeholder{
    position: absolute;
   
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    vertical-align: middle;
    bottom: 5px;
    /* Brand Colors/Safe Blue */
    border: none;
outline: none;
    color: #213088;

}
.Price-Per-Week-Counter-Text:focus{
   display: block;
  
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    bottom: 5px;
    
    /* Brand Colors/Safe Blue */
    border: none;
outline: none;
    color: #213088;

}

.Total-Spending-Text{
    position: absolute;
left: calc(50% - 272px/2);

top: 383px;


font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
display: block;
align-items: center;
text-align: left;
letter-spacing: -0.02em;

color: #585858;
}
.Total-Spending-Per-Month{
    position: absolute;
left: calc(50% - 272px/2);
width: 100%;

top: 424px;


font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 36px;
text-align: left;
display: block;
align-items: center;
letter-spacing: -0.02em;

/* Gradients/Vertical */

background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.Per-Month-Text{
    position: absolute;
    left: calc(50% - 272px/2);
    right: calc(50% - 272px/2);
    top: 460px;
   
    width: 100%;
  
    height: 14px;;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    display: inline;
    text-align: left;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* Gradients/Vertical */
    
    background: #585858;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.Total-Spending-Per-Year{
    position: absolute;
left: calc(50% - 272px/2);
right: calc(50% - 272px/2);
top: 482px;
width: 100%;




font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 36px;
display: block;
text-align: left;
align-items: center;
letter-spacing: -0.02em;

/* Gradients/Vertical */

background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.Per-Year-Text{
    position: absolute;
    left: calc(50% - 272px/2);
    top: 516px ;
width: 100px;
height: 17px;
text-align: left;

    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    display: inline-block;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* Gradients/Vertical */
    
    background: #585858;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.Typical-Ready-Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 38.03px;
    left: calc(50% - 285px/2);
    top: 83.748%;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}
.Typical-Ready-Button a{
   color: white;
}