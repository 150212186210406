@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.AllDone {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  

  height: 100%  ;
  top: 0px;


}

.AllDone-header {
    position: absolute;
    left: 33.6%;
    width:123px;
    top: 8.37%;
    height:18px;
  background: url(Horizontal_Logo_Smaller.png);
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;

/* Suggestive/Grey */

}

.All-Done-Div{
    position: absolute;
left: calc(50% - 237px/2);

top: 20.07%;

}

.All-Done-Text{
    position: absolute;
left: 0;
top: 0;
width:170px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
/* identical to box height */

letter-spacing: -0.02em;

/* Gradients/Vertical */

background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent
}
.Celebration-Emoji{
        position: absolute;
    left: 179px;
   top:0px;
   width: 30px;
   height: 30px;
    background: url(CelebrationEmoji.svg);
}

.Complete-Set-Up-Text{
    position: absolute;
left: calc(50% - 237px/2);

top: 28.08%;
width:283px;
height:59px;



font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
text-align: left;
letter-spacing: -0.02em;

/* Reframe - Safe Blue */

color: #213088;
}

.Next-Steps{
    position: absolute;
left: calc(50% - 237px/2);

top: 40.89%;

width:237px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 17px;
text-align: left;
display: block;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Next-Steps span{
    color:#213088;
}




