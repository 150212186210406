@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.DoctorScreen {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  

  height: 100%  ;
  top: 0px;


}

.Doctor-header {
    position: absolute;
    left:calc(50% - 123px/2);
   width: 123px;
   height: 18px;
    top: 8.37%;
    
  background: url(Horizontal_Logo_Smaller.png);
}

.Doctors-Div{
    position: absolute;
left: calc(50% - 289px/2);
top:12.4876%;
}

.Mark-Name{
    position: absolute;
width: 121px;
height: 30px;
left :118px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

/* Brand Colors/Safe Blue */

color: #213088;
}
.Mark-Title{
    position: absolute;
width: 47px;
height: 9px;
top:20px;
left:118px;



font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}
.Mark-Position{
    position: absolute;
width: 108px;
height: 30px;
left:118px;
top:41px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Mark-Img{
    box-sizing: border-box;

position: absolute;
width: 80px;
height: 80px;


background: url(Mark-Img.png);
/* Brand Colors/Serene */

}

.Michael-Name{
    position: absolute;
    width: 143px;
    height: 36px;   
    top:96px;
    left:118px;
 
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* Reframe - Safe Blue */
    
    color: #213088;
}
.Michael-Title{
    position: absolute;
    width: 179px;
    height: 86px;
    left:118px;
    top: 84px;

    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #585858;
    
}
.Michael-Position{
    position: absolute;
width: 108px;
height: 30px;
top: 142px;
left:118px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Michael-Img{
    box-sizing: border-box;

    position: absolute;
    width: 80px;
    height: 80px;
    top:103px;

    background: url(Michael-Img.png);
    /* Brand Colors/Serene */
    
    
}

.Frank-Name{
    position: absolute;
width: 171px;
height: 13px;
left: 118px;
top: 216px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

/* Brand Colors/Safe Blue */

color: #213088;
}
.Frank-Title{
    position: absolute;
width: 171px;
height: 30px;
top:222px;
left:118px;



font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #585858;


    
}
.Frank-Position{
    position: absolute;
    width: 171px;
    height: 30px;
    top:247px;
  
    left:118px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #585858;
}

.Frank-Img{
    box-sizing: border-box;

position: absolute;
width: 80px;
height: 80px;
top: 206px;

background: url(Frank-Img.png);
/* Brand Colors/Serene */

}

.neuroscience{
    position: absolute;
    width: 299px;
    height: 163px;
    left: calc(50% - 299px/2);
    top: 61.157%;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: inline-block;
    align-items: center;
    text-align: left;
    letter-spacing: -0.02em;
    
    color: #213088;
     
}




.Doctor-Ready-Text{
    width: 35px;
height: 19px;
left: 125px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

/* Brand Colors/White */

color: #fff;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.Doctor-Ready-Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 38.03px;
    left: calc(50% - 285px/2);
    top: 83.74%;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}