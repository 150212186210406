@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.AlcoholReuction {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  

  height: 100%  ;
  top: 0px;


}

.Reduction-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width: 123px;
    height: 18px;
    top: 8.37%;
  
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}



.Reduction-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}
.Reduction-Progress-Indicator-Gradient{
    position: absolute;
left: 26px;
width:80px;
height: 8px;

top: 11px;


background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
border-radius: 2.61658px;
}
.Reduction-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Reduction-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Reduction-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Reduction-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #1160A5;
}

.Reduction-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Reduction-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #C4C4C4;
}
.Reduction-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Reduction-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;
}
.Reduction-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Reduction-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Reduction-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    
    
    /* Completed */
    
    
}

.Which-Path{
    position: absolute;
    left:calc(50% - 253px/2);
    right:calc(50% - 253px/2);
   
    top: 29.55665%;
    
    
    font-family: 'Inter';
    font-style: normal;
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    
    /* Reframe - Safe Blue */
    
    color: #213088;
    
}




.Alcohol-Main-Div{
    position: absolute;
    width: 260.11px;
    height: 210px;
    left: calc(50% - 260px/2);
    top: 40.5044%;


}
.Quit-Div{
    box-sizing: border-box;

    
    position: absolute;
    
    width: 260px;
    cursor: pointer;


height:39px;
    border: 1px solid #213088;
    border-radius: 12px;
}

.Quit-Text{
    position: absolute;
    left: 13.77px;
   
    

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    width: 232.47px;
    height: 23px;
    font-size: 12px;
    line-height: 39px;
    text-align: center;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #213088;
}
.Cut-Div{
    box-sizing: border-box;

    
    position: absolute;
    
    width: 260px;
    top: 51px;
    cursor: pointer;


    height:39px;
    border: 1px solid #213088;
    border-radius: 12px;
}
.Cut-Text{
    position: absolute;
    left: 26.6px;
    height:39px;
   

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    width: 207.24px;
    
    font-size: 12px;
    line-height: 39px;
    text-align: center;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #213088;
}
.Continue-Div{
    box-sizing: border-box;

    
    position: absolute;
    
    width: 260px;
    top:102px;
    cursor: pointer;


    height:57px;
    border: 1px solid #213088;
    border-radius: 12px;
}
.Continue-Text{
    position: absolute;
    left: 13.48px;
   
    top:calc(50% );
    transform: translateY(-50%);

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    width: 233.04px;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #213088;
}
.Not-Sure-Div{
    box-sizing: border-box;

    
    position: absolute;
    top:171px;
    width: 260px;
    cursor: pointer;

height:39px;
    border: 1px solid #213088;
    border-radius: 12px;
}
.Not-Sure-Text{
    position: absolute;
    left: 13.77px;
   
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    width: 232.47px;
    height: 39px;
    font-size: 12px;
    line-height: 39px;
    text-align: center;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;

    color: #213088;
}












.Ready-Section div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 38.03px;
    left: 45px;
    top: 680px;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}