@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.YoullSave {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  

  height: 100%  ;
  top: 0px;


}

.YoullSave-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width: 123px;
    top: 8.37%;
    height:18px;
  background: url(Horizontal_Logo_Smaller.png);
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}


.Save-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}
.Save-Progress-Indicator-Gradient{
    position: absolute;
left: 26px;
width:211px;
height: 8px;

top: 11px;


background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
border-radius: 2.61658px;
}
.Save-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Save-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Save-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Save-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #1160A5;
}

.Save-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Save-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #1D3F91;
}
.Save-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Save-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #213189;
}
.Save-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Save-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Save-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    
    
    /* Completed */
    
    
}


.Youll-Save-Text{
    position: absolute;
    left: calc(50% - 272px/2);
    display: block;
    top: 29.9261%;
    margin: 0;
    padding: 0;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    
    /* Reframe - Safe Blue */
    
    color: #213088;

}

.Rem-Div{
    position: absolute;
    left: calc(50% - 159px/2);
    top: 43.133%;

    height:67px;

     
}
.Rem-Cycles-Num{
    position: absolute;
left: 45px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 26px;
/* or 87% */

display: block;
align-items: center;
text-align: left;
padding: 0;
margin: 0;
letter-spacing: -0.02em;

/* Gradients/Alt Main */

background: linear-gradient(180deg, rgba(33, 48, 136, 0.76) 0%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.Rem-Cycles-Text{
    position: absolute;

top: 34px;
width:200px;

padding: 0;
margin: 0;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
display: flex;
text-align: center;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Money-Saved-Div{
    position: absolute;
    width:104px;
left: calc(50% - 104px/2);
right: calc(50% - 104px/2);

top: 54.72%;

}

.Money-Saved-Num{
    position: absolute;    

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    padding: 0;
    font-size: 30px;
    line-height: 26px;
    /* or 87% */
    
    display: flex;
    text-align: center;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* Gradients/Alt Main */
    
    background: linear-gradient(180deg, rgba(33, 48, 136, 0.76) 0%, #018ABE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.Saved-Text{
    position: absolute;
    left: 26px;
    top: 34px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    /* or 144% */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #585858;
}

.Calories-Saved-Div{
    position: absolute;
    left: calc(50% - 181px/2);
    width: 181px;
    top: 66.32%;
}
.Calories-Saved-Text{
    position: absolute;
    width: fit-content;
    left:55px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 26px;
text-align: center;
/* or 87% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

/* Gradients/Alt Main */

background: linear-gradient(180deg, rgba(33, 48, 136, 0.76) 0%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.Empty-Calories-Text{
    position: absolute;

top: 34px;
width: 180px;


font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
display: flex;
text-align: center;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}



.Save-Ready-Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 38.03px;
    left: calc(50% - 285px/2);
    top: 83.7438%;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088;
    border-radius: 50px;
}

.Save-Ready-Button a{
    width: 100px;
    height: 22px;
    left: 105px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: block;
    align-items: center;
    
    text-align: center;
    letter-spacing: -0.02em;
    
    /* Brand Colors/White */
    
    color: #ffffff;
    
}