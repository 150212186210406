@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

:root{
    overflow-x: hidden;
    overflow-y: scroll;
    
}
.CustomPlan {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  

  height: 1006px;
  top: 0px;


}

.CustomPlan-header {
    position: absolute;
left: calc(50% - 123px/2);
width:123px;
top: 5.15%;
height:18px;

  
}

.Custom-Back-Arrow{
    position: absolute;
    left: 11.466%;
    top: 9.311%;
    width:18px;
    height: 12px;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}
.Custom-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:12.33913%;
    width:295px;
    height: 31px;
}
.Custom-Progress-Indicator-Gradient{
    position: absolute;
left: 26px;
width:211px;
height: 8px;

top: 11px;


background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
border-radius: 2.61658px;
}
.Custom-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Custom-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Custom-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Custom-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #1160A5;
}

.Custom-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Custom-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #1D3F91;
}
.Custom-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Custom-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #213189;
}
.Custom-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Custom-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Custom-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    
    
    /* Completed */
    
    
}


.Custom-Reframe-Plan-Text{
    position: absolute;
width: 278px;
height: 50px;
left: calc(50% - 287px/2);
top: 134px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 19px;
text-align: left;
display: block;
align-items: center;
letter-spacing: -0.02em;

color: #213088;
}

.Goals-Div{
    position: absolute;
width: 248px;
height: 183px;
left: calc(50% - 248px/2);
top: 167px;
}
.Primary-Goal-Text{
    position: absolute;


font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

/* Suggestive/Grey */

color: #8A8A8A;
}
.Primary-Goal-Div{
    top:45px;
    box-sizing: border-box;

position: absolute;
width:248px;
height: 40px;
left: 0px;
margin: 0px;
padding: 0px;

background: linear-gradient(99.43deg, rgba(255, 255, 255, 0.67) 27.49%, rgba(255, 255, 255, 0.44) 79.42%);
border: 1px solid #213088;
border-radius: 12px;
}
.Primary-Goal-Gradient{
    position: absolute;
   margin: 0px;
   left: 56px;
   top: 13px;


font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 26px;

/* or 144% */

display: block;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

/* Gradients/Alt Main */

background: linear-gradient(180deg, rgba(33, 48, 136, 0.76) 0%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.Secondary-Goal-Text{
    position: absolute;
    top:98px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
height:45px;
display: block;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

/* Suggestive/Grey */

color: #8A8A8A;
}
.Secondary-Goal-Div{
    top:143px;
    box-sizing: border-box;

position: absolute;
width:248px;
height: 40px;
left: 0px;
margin: 0px;
padding: 0px;

background: linear-gradient(99.43deg, rgba(255, 255, 255, 0.67) 27.49%, rgba(255, 255, 255, 0.44) 79.42%);
border: 1px solid #213088;
border-radius: 12px;
}
.Secondary-Goal-Gradient{
    position: absolute;
   margin: 0px;
   left: 56px;
   top: 13px;


font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 26px;

/* or 144% */

display: block;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

/* Gradients/Alt Main */

background: linear-gradient(180deg, rgba(33, 48, 136, 0.76) 0%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.How-Get-There-Text{
    position: absolute;
width: 244px;
height: 54px;
left: calc(50% - 287px/2);

top: 170px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
letter-spacing: -0.02em;

color: #213088;
}
.Checklist-Div{
    position: absolute;
width: 287px;
height: 187px;
left: calc(50% - 287px/2);
right: calc(50% - 287px/2);
top: 227px;
}

.Neuroscience-Based-Image{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0px;
    left: 0px;
    background: url(GreenCheck.svg);
}
.Neuroscience-Based-Text{
    left:34px;
    position: absolute;

    top: 1px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
max-lines: 2;
font-size: 14px;
line-height: 17px;
display: block;
text-align: left;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}
.Daily-Based-Image{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 43.38px;
    left: 0px;
    background: url(GreenCheck.svg);
}
.Daily-Based-Text{
    left:34px;
    position: relative;

    top: 45px;
    bottom: 0px;
    max-lines: 2;
    height: 34px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
display: block;
text-align: left;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Tools-Based-Image{
    position: absolute;
    width: 20px;
    height: 20px;
    top: 74px;
    left: 0px;
    background: url(GreenCheck.svg);
}
.Tools-Based-Text{
    left:34px;
    position: absolute;
    height: 34px;;
    top: 72px;
    max-lines: 2;
    width: 260px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
display: block;
text-align: left;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}
.Community-Based-Image{
    position: absolute;
    top: 118px;
    left: 0px;
    width: 20px;
    height: 20px;
    background: url(GreenCheck.svg);
}
.Community-Based-Text{
    left:34px;
    position: absolute;

    top: 119px;
    max-lines: 2;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
display: block;
text-align: left;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}
.Coach-Based-Image{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 159px;
    left: 0px;
    background: url(GreenCheck.svg);
}
.Coach-Based-Text{
    left:34px;
    position: absolute;
    top: 161px;
    max-lines: 2;
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
display: block;
text-align: left;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}
.Youll-Save-Approximately{
    position: absolute;
width: 260px;
height: 54px;
left: calc(50% - 260px/2);
top: 450px;
max-lines: 1;


font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
display: block;
text-align: center;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}

.Amount-Saved-Text{
    position: absolute;
    height: 44px;
    
    top: 494px;

    left:50%;
    width:200px;
    transform:translateX(-50%);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 26px;
    /* or 87% */
    
    display: block;
    align-items: center;
    letter-spacing: -0.02em;
    
    /* Gradients/Main */
    
    background: linear-gradient(180deg, #018ABE 0%, #213088 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.Per-Year-Saving-Text{
    
    position: absolute;
    top:537px;
    left: calc(50% - 61px/2);
    font-family: 'Inter';
    width: 61px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    /* Gradients/Vertical/Blue */    
    background: linear-gradient(90deg, #213088 0%, #018ABE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    /* Inside auto layout */
}
.Our-Promise-Text{
    position: absolute;
width: 277px;
height: 22px;
left: calc(50% - 277px/2);
top: 588px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
display: block;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

/* Brand Colors/Safe Blue */

color: #213088;
}

.Monthly-Div:hover{
    border: 2px solid #018ABE;
/* card-selection */

box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}

.Monthly-Div:active{
    border: 2px solid #018ABE;
/* card-selection */

box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}

.Monthly-Div{
    position: absolute;
    left: calc(50% - 117px);
   width: 117px;
   height:143px;
    top: 655px;
    margin-right: 8px;
    cursor: pointer;
    display: block;
   
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* card-left */
    
    box-shadow: 0px 0px 5px rgba(109, 109, 109, 0.25);
    border-radius: 10px;
}
.Free-Trial-Text-Monthly{
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    left: 12px;
    top: 23px;
line-height: 15px;
/* identical to box height */

display: block;
text-align: center;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

/* Brand Colors/Safe Blue */

color: #213088;


/* Inside auto layout */


}
.Monthly-Price-Now{
    position: absolute;
    width: 57px;
    height: 14px;
    left:calc(50% - 57px/2);
    top: 56px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #414141;
}
.Monthly-Price-Per-Month{
    position: absolute;
    width: 102px;
    height: 10px;
    left: calc(50% - 102px/2);
    top: 80px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    /* Suggestive/Grey */
    
    color: #8A8A8A;
}
.Monthly-Text{
    position: absolute;
    left: calc(50% - 46px/2);
    width: 46px;;
  
    top: 81.98%;
   
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    
    display: block;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    
    color: #585858;
}



.Yearly-Div:focus{
    border: 2px solid #018ABE;
    /* card-selection */
    
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.Yearly-Div:focus + .Yearly-Percent-Off-Div{
    background-color: #018ABE;
}

.Yearly-Div{
    box-sizing: border-box;
    margin-left: 8px;
    cursor: pointer;

    position: absolute;
    left:  calc(50% );
    width:117px;
    height:143px;
    top: 655px;
    
    
    background: #FFFFFF;
    /* Brand Colors/Blue */
    
    border: 2px solid #018ABE;
    /* card-selection */
    
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.Free-Trial-Text-Yearly{
 
    
position: absolute;
font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 12px;
left: 12px;
top: 23px;
line-height: 15px;
/* identical to box height */
display: inline-block;
letter-spacing: -0.02em;

/* Brand Colors/Safe Blue */

color: #213088;
}
.Yearly-Price-Now{
    position: absolute;
    width: 57px;
    height: 14px;
    left: 32px;
    top: 56px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #414141;
}
.Yearly-Price-Per-Month{
    position: absolute;
    width: 102px;
    height: 10px;
    left: 20px;
    top: 80px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    /* Suggestive/Grey */
    
    color: #8A8A8A;
}
.Yearly-Text{
    position: absolute;
    left: 40px;
    right: 62.67%;
    top: 81.98%;
    bottom: 16.88%;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    text-transform: capitalize;
    
    color: #585858;
}

.Yearly-Percent-Off-Div{
    position: absolute;
    
    left:  24px;
width:70px;
height: 19px;
top: -11px;
background: #018ABE;
border-radius: 3px;
}

.Yearly-Percent-Off-Text{
    position: absolute;
left:8px;
top: 4px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;
display: flex;
align-items: center;
letter-spacing: -0.02em;
color: #FFFFFF;
}

.Fair-Trial-Div{
    position: absolute;
width: 260px;
height: 357px;
left: calc(50% - 260px/2);
top: 500px;
z-index: 1;
visibility: hidden;
background: #FFFFFF;
border-radius: 17px;
filter: drop-shadow(0px 0px 10px rgba(109, 109, 109, 0.25));
}

.Cancel-Plan-Div{
    position: absolute;
    background: url(BlackCancelIcon.svg);
    height: 10px;
    width: 10px;
    left: 235px;
    cursor: pointer;
    top: 15px;
}

.Fair-Trial-Disclaimer{
    position: absolute;
left: calc(50% - 245px/2);
width: 245px;;
height: 16px;
top: 39px;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
display: block;
align-items: center;

text-align: center;
letter-spacing: -0.02em;

/* Brand Colors/Safe Blue */

color: #213088;

}

.Ten-Minutes-Text{
    position: absolute;
width: 190px;
height: 32px;
left:  calc(50% - 190px/2);
top: 75px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
display: block;
align-items: center;
text-align: left;
letter-spacing: -0.02em;

color: #585858;
}
.Ten-Minutes-Text b{
    color: #213088;
  }

.Cancel-Program-Text{
    position: absolute;
width: 190px;
height: 60px;
left: calc(50% - 190px/2);
top: 116px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
display: block;
align-items: center;

letter-spacing: -0.02em;

color: #585858;

}
.Cancel-Program-Text b{
    color: #213088;
  }

.Waste-Text{
    position: absolute;
width: 190px;
height: 60px;
left: calc(50% - 190px/2);
top: 194px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
display: block;
align-items: center;
letter-spacing: -0.02em;

color: #585858;
}
.Waste-Text b{
  color: #213088;
}

.Im-In-Button{
    position: absolute;
left:  calc(50% - 207px/2);
height: 36px;
width: 207px;
cursor: pointer;

top:285px;


/* Brand Colors/Safe Blue */

background: #213088;
border-radius: 40px;
}

.Im-In-Button-Text{
    position: absolute;
width: 166px;
height: 36px;
left: calc(50% - 166px/2);
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 36px;
display: block;
align-items: center;
text-align: center;
letter-spacing: -0.02em;
/* Brand Colors/White */
color: #FFFFFF;
}


.Automatic-Renewal-Text{
    position: absolute;
width: 274px;
height: 26px;
left: calc(50% - 274px/2);
top: 813px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 13px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.05em;

/* Suggestive/Grey */

color: #8A8A8A;
}
.Terms-Conditions-Text{
    position: absolute;
    left: calc(50% - 225px/2);
    width: 225px;
    height:39px;
    top: 865px;
    display: inline-block;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
  
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    /* Suggestive/Grey */
    
    color: #8A8A8A;  
}
.Start-Free-Trial-Button{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px;
gap: 10px;
cursor: pointer;

position: absolute;
width: 285px;
height: 39px;
left: calc(50% - 285px/2);
top: 910px;

/* Brand Colors/Safe Blue */

background: #213088;
border-radius: 50px;
}
.Start-Free-Trial-Text{
    width: 285px;
height: 15px;

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
/* identical to box height */

display: block;
align-items: center;
max-lines: 1;
text-align: center;
letter-spacing: -0.02em;

/* Brand Colors/White */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.Cancel-Anytime-Text{
    position: absolute;
    width: 81px;
    height: 15px;
    left: calc(50% - 81px/2);
    top: 955px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.05em;
    
    /* Suggestive/Grey */
    
    color: #8A8A8A;
     
}