@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.CustomPlanTransitionScreen {
  text-align: center;
  background: url(MainBackground.png);
  position: absolute;
  width: 375px;
  

  height: 812px  ;
  top: 0px;


}

.Custom-header {
  position: absolute;
  align-self: center;
  width: 255px;
  height: 39px;
  left: 60px;
  justify-content: center;
  top: 85px;
  background: url(Horizontal_Logo.png);
}


p{
position: absolute;
width: 330px;
height: 87px;
left: 22px;
top: 161px;

font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.02em;

color: #213088;
}
