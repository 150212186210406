@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');
:root{
    overflow-x: hidden;
    overflow-y: scroll;
}
.ConfirmationCode {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  z-index: -1;
  

  height: 100%  ;
  top: 0px;


}

.ConfirmationCode-header {
    position: absolute;
    left: calc(50% - 123px/2);
    width:123px;
    top: 8.37%;
    height:18px;
 
}

.Back-Arrow{
    position: absolute;
    left: 11.73%;
    top: 15.27%;
    width:18px;
    height: 12px;
    
    /* Suggestive/Grey */
    
    filter: invert(65%) sepia(0%) saturate(1%) hue-rotate(348deg);
}



.Code-Progress-Bar{
    position: absolute;
    left:calc(50% - 295px/2);
    top:21.9285%;
    width:295px;
    height: 31px;
}
.Code-Progress-Indicator-Gradient{
    position: absolute;
left: 26px;
width:211px;
height: 8px;

top: 11px;


background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
border-radius: 2.61658px;
}
.Code-Progress-Indicator{
    position: absolute;
width: 262px;
height: 8px;
left: 15px;
top: 11px;
z-index: -2;

background: #F4F4F4;
border-radius: 2.61658px;

}

.Code-Name-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left:0px;
    
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    border-radius: 50%;
    /* Brand Colors/Blue */
    
    border: 2.5px solid #018ABE;

}
.Code-Name-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Code-Flag-Icon-Div{
    box-sizing: border-box;

position: absolute;
width: 30px;
height: 30px;
left: 67.06px;
top: 0px;

background: #FFFFFF;
border-radius: 50%;
/* Suggestive/Divider */

border: 2.5px solid #1160A5;
}

.Code-Flag-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

/* Suggestive/Divider */


/* Suggestive/Divider */



}

.Code-Pin-Icon-Div{
    box-sizing: border-box;

    position: absolute;
    width: 30px;
    height: 30px;
    left: 132.92px;
    top: 0px;
    
    /* Brand Colors/White */
    
    background: #FFFFFF;
    /* Suggestive/Divider */
    border-radius: 50%;

    border: 2.5px solid #1D3F91;
}
.Code-Pin-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


}

.Code-Brain-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left:199px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #213189;
}
.Code-Brain-Icon{
    position: absolute;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

   
}
.Code-Check-Icon-Div{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 265px;
    top: 0px;
    border-radius: 50%;
    background: #ffffff;

    border: 2.5px solid #C4C4C4;

}
.Code-Check-Icon{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
    
    
    /* Completed */
    
    
}

.Confirmation-Main-Div{
    position: absolute;
    left: calc(50% - 285px/2);
    width: 285px;
    right: calc(50% - 285px/2);
    top: 29.93%;
}
.Six-Digit-Text{
    position: relative;
   margin: 0px;
    width: 285px;
    height: 80px;
    left:0px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    line-height: 22px;
    letter-spacing: -0.02em;
    
    /* Reframe - Safe Blue */
    
    color: #213088;
}
.Email-Sent-Text{
    position: absolute;
    top:61px;
    left: 0px;
    right: 0px;

    width: 285px;
      
    padding: 0;
    margin: 0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* or 138% */
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    
    display: block;
    text-align: left;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: #213088;
}

.Code-Error-Text{
    left: 2px;
    top: 250px;
    width: 300px;
    overflow-wrap: break-word;
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    display: block;
    text-align: left;
    align-items: center;
    letter-spacing: -0.02em;
    
    color: red;
}







.Confirmation-Code-Text{
    position: absolute;
    left: 4px;
    top: 180px;
    height: fit-content;
    margin: 0;
    padding: 0;
    margin-top: 10px;
    width: 285px;;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    display: block;
    align-items: center;
    letter-spacing: -0.02em;
    border: none;
    
    /* Grey */
    
    color: #8A8A8A;
    
}
.Confirmation-Code-Text::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }




.Confirmation-Code-Text::placeholder {
    position: absolute;
    left: 0;
    top:0px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    
    font-size: 14px;
    line-height: 17px;
    display: block;
    padding: 0;
    height: fit-content;
    align-items: center;
    letter-spacing: -0.02em;
    margin: auto;

    
    /* Grey */
    
    color: #8A8A8A;
}

.Didnt-Get-Code-Text{
    position: absolute;
left: 4px;
width:300px;
top:225px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 13px;
display: block;
text-align: left;
align-items: center;
letter-spacing: -0.02em;

/* Suggestive/Grey */

color: #8A8A8A;
}



.Code-Ready-Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    cursor: pointer;
    position: absolute;
    width: 285px;
    height: 39px;
    left: calc(50% - 285px/2);
    top: 83.7438%;
    /* Brand Colors/Safe Blue */
    background: #213088;
    border-radius: 50px;
}

.Code-Ready-Button a{
    width: 100px;
    height: 22px;
    left: 105px;  
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: block;
    align-items: center;
    
    text-align: center;
    letter-spacing: -0.02em;
    
    /* Brand Colors/White */
    
    color: #ffffff;
    
}