@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=League+Spartan&family=Montserrat:wght@400;700&display=swap');

.ResearchAnalyticsScreen {
  text-align: center;
  background: white;
  position: absolute;
  width: 100%;
  

  height: 100%  ;
  top: 0px;


}

.Research-header {
    position: absolute;
    left:calc(50% - 123px/2);
    width: 123px;
    height: 18px;
    top: 8.37%;
    
  background: url(Horizontal_Logo_Smaller.png);
}


.Years-Research{
    position: absolute;
width: 283px;
height: 46px;
left: calc(50% - 283px/2);
top: 17.4%;
display: block;
padding: 0;
margin: auto;
font-family: 'Inter';
text-align: center;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;

letter-spacing: -0.05em;

color: #585858;

}
.Data-Points{
    position: absolute;
width: 72px;
height: 15px;
left: calc(50% - 72px/2);
top: 33.2%;
margin-top: 5px;

font-family: 'Inter';
font-style: normal;
display: flex;
font-weight: 600;
font-size: 14px;
line-height: 17px;
letter-spacing: -0.05em;

color: #585858;
}
.Two-Million{
    position: absolute;
width: 79px;
height: 36px;
left: calc(50% - 79px/2);
top: 27.8%;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 44px;
letter-spacing: -0.05em;

/* Gradients/Vertical */

background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.Percentage{
    position: absolute;
width: 83px;
height: 38px;
left: calc(50% - 83px/2);
top: 39.77%;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 44px;
text-align: center;
letter-spacing: -0.05em;

/* Gradients/Vertical */

background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.Users-Noted{
    position: absolute;
width: 208px;
height: 36px;
left: calc(50% - 208px/2);
top: 44.95%;
margin-top: 5px;


font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
text-align: center;
letter-spacing: -0.05em;

color: #585858;
}

.Four-Thousand{
    position: absolute;
width: 118px;
height: 36px;
left: calc(50% - 118px/2);
top: 52.5862%;

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 44px;
letter-spacing: -0.05em;

/* Gradients/Vertical */

background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}

.Stars-Background{
    position: absolute;
    width: 95px;
    height: 18px;
    margin-top: 10px;
    left: calc(50% - 95px/2);
    top: 58.25%;
    /* Gradients/Vertical */
    background-clip: text;
    background: url(Five_Stars_Review.png);
    transform: matrix(-1, 0, 0, 1, 0, 0);
}




.Reviews{
    position: absolute;
    width: 51px;
    height: 17px;
    left: calc(50% - 51px/2);
    top: 61.5763%;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    
    color: #585858;  
}

.Reframe-Works{
    position: absolute;
    width: 266px;
    height: 27px;
    left: calc(50% - 266px/2);
    top: 71.1822%;
    
    font-family: 'Inter';
    font-style: normal;
    display: block;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    
    /* Gradients/Vertical */
    
    background: linear-gradient(270deg, #213088 0%, #018ABE 100%, #018ABE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent; 
}


.Research-Ready-Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    
    gap: 10px;
    
    position: absolute;
    width: 285px;
    height: 38.03px;
    left: calc(50% - 285px/2);
    top: 83.743%;
    
    /* Brand Colors/Safe Blue */
    
    background: #213088    ;
    border-radius: 50px;
}

.Research-Ready-Button a{
    width: 100px;
    height: 22px;
    left: 105px;
    
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: block;
    align-items: center;
    
    text-align: center;
    letter-spacing: -0.02em;
    
    /* Brand Colors/White */
    
    color: #ffffff;
    
}